import { CloseIcon } from '@tapestry/shared/icons';
import { FC, memo } from 'react';
import ReactTimePicker, {
  TimePickerProps as ReactTimePickerProps,
} from 'react-time-picker';
import { twMerge } from 'tailwind-merge';

import './time-picker.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sanitiseForNativeInput } from './utils/sanitise-for-native-input';
import { isTouchDeviceButNotComputer } from './utils/is-touch-device-but-not-computer';

export type TimeString = `${string}:${string}`;
export type extractedTimePickerProps = Pick<
  ReactTimePickerProps,
  'onBlur' | 'disabled' | 'name' | 'id'
>;

export interface TimePickerProps extends extractedTimePickerProps {
  clearable?: boolean;
  hasBorders?: boolean;
  onChange: (time: TimeString | null) => void;
  value: string | Date | null;
}

const TimePicker: FC<TimePickerProps> = memo((props) => {
  let { nativeTimePickerOnMobileDevices } = useFlags();
  let { clearable = true, hasBorders = true, onChange, value } = props;
  let isTouchDevice = isTouchDeviceButNotComputer();

  const handleOnChange = (time: string | null) => {
    onChange(time as TimeString | null);
  };

  if (nativeTimePickerOnMobileDevices && isTouchDevice) {
    let sanitisedNativeValue = sanitiseForNativeInput(value);

    return (
      <input
        aria-label="Time"
        type="time"
        value={sanitisedNativeValue}
        onChange={(e) => handleOnChange(e.target.value || null)}
        className={twMerge(
          'relative',
          'h-full w-full min-w-[110px] rounded-md bg-white sm:min-w-48',
          'pl-12', // moves the text to the right (also don't think it works on safari IOS)
          'text-gray-text placeholder-gray-text text-sm uppercase sm:text-xl',
          // * ring does not work on safari
          'focus:ring-orange-hank focus:outline-none focus:ring-1 active:bg-gray-100 disabled:bg-white'
        )}
        disabled={props.disabled}
        readOnly={props.disabled}
      />
    );
  }

  return (
    <ReactTimePicker
      clearIcon={
        clearable ? <CloseIcon light fillColor="currentColor" /> : null
      }
      clockIcon={null} // disable clock panel
      disableClock={true} // disable clock panel
      hourPlaceholder="HH"
      minutePlaceholder="MM"
      maxDetail="minute"
      format="hh:mm a"
      className={twMerge(
        'h-full w-full rounded-md',
        hasBorders && 'border-gray-border border'
      )}
      {...props}
      onChange={handleOnChange}
    />
  );
});

export { TimePicker };
