import {
  IShopSelectorGroupType,
  IThreadV4Type,
} from '@tapestry/shared/graphql';
import { Nullable } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';

export const LETTERS_AND_NUMBERS_KEYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
];

export const getSearchPlaceholder = (
  label: string | null | undefined,
  placeholder: string | undefined
): string => {
  if (placeholder) {
    return placeholder;
  }

  if (!label) return 'Search...';

  return `Search ${label} and more...`;
};

export const getActiveShopFromGroupedShop = (
  groupList: IShopSelectorGroupType[],
  currentActiveShopId: Maybe<string>,
  activeGroupId: Maybe<string>
): IThreadV4Type | IShopSelectorGroupType | null => {
  if (activeGroupId) {
    return groupList?.find((group) => group.id === activeGroupId) || null;
  }

  if (!currentActiveShopId) return null;

  let currentActiveShop: Nullable<IThreadV4Type> = null;

  groupList?.forEach((group) => {
    const possibleActiveShop = group?.shops?.find(
      (shop) => shop?.id === currentActiveShopId
    );

    if (possibleActiveShop) {
      currentActiveShop = possibleActiveShop;
    }
  });

  return currentActiveShop;
};
