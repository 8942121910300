import { Dispatch, FC, SetStateAction, cloneElement } from 'react';
import { BannerManager } from '../../BannerManager';
import { ChatIcon, HamburgerIcon } from '@tapestry/shared/icons';
import { NavbarSearchInput } from '../../navbar-search';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { twMerge } from 'tailwind-merge';
import { getCurrentAppInfo, trackEvent } from '@tapestry/shared/utils';
import { Link } from '@tapestry/weave';
import { ROUTE_PATHS } from '@tapestry/shared/constants';

export interface ITopBarsProps {
  noShadowOnNavbar?: boolean;
  setSidebarExpanded: Dispatch<SetStateAction<boolean>>;
  noBanner?: boolean;
  isPublicPage?: boolean;
  userIsNotRegistered?: boolean;
}

const {
  appInfo: { brandIcons },
  isRetailApp,
} = getCurrentAppInfo();

const ICON_PROPS = {
  'aria-hidden': 'true',
  fillColor: 'currentColor',
  className: 'h-6 hidden xl:block',
};

export const TopBars: FC<ITopBarsProps> = ({
  noShadowOnNavbar,
  setSidebarExpanded,
  noBanner,
  isPublicPage,
  userIsNotRegistered,
}) => {
  const { heartbeatInbox } = useFlags();

  const BrandIcon = cloneElement(<brandIcons.long />, ICON_PROPS);

  return (
    <div id="top-bars" className="z-navbar sticky top-0">
      {!noBanner && (
        <BannerManager
          isPublicPage={isPublicPage}
          userIsNotRegistered={userIsNotRegistered}
        />
      )}

      <header
        className={twMerge(
          'flex flex-row items-center justify-center border-b bg-white px-4 py-3',
          noShadowOnNavbar ? 'border-gray-border border-b' : 'shadow-md'
        )}
      >
        {/* * In Retail+, the brand logo goes to the homepage instead of toggling the sidebar */}
        {isRetailApp ? (
          <>
            <button
              type="button"
              className="text-primary -m-2 mr-2 block p-2 xl:hidden"
              onClick={() => {
                setSidebarExpanded((state: boolean) => {
                  trackEvent({
                    event: 'SidebarEvent',
                    category: 'sidebar',
                    action: 'top_bar_logo_clicked',
                    label: `to ${state ? 'close' : 'open'}`,
                  });

                  return !state;
                });
              }}
            >
              <span className="sr-only">toggle sidebar</span>
              <HamburgerIcon className="h-4 w-auto" />
            </button>

            <Link
              href={ROUTE_PATHS.homepage}
              className="text-primary -m-2 mr-2 hidden p-2 xl:block"
            >
              {BrandIcon}
            </Link>
          </>
        ) : (
          <button
            type="button"
            className="text-primary -m-2 mr-2 block p-2"
            onClick={() => {
              setSidebarExpanded((state: boolean) => {
                trackEvent({
                  event: 'SidebarEvent',
                  category: 'sidebar',
                  action: 'top_bar_logo_clicked',
                  label: `to ${state ? 'close' : 'open'}`,
                });

                return !state;
              });
            }}
          >
            <span className="sr-only">toggle sidebar</span>
            <HamburgerIcon className="h-4 w-auto xl:hidden" />
            {BrandIcon}
          </button>
        )}

        <div className="grow">
          <NavbarSearchInput />
        </div>

        {heartbeatInbox && (
          <button className="ml-4">
            <span className="sr-only">open chat</span>
            <ChatIcon />
          </button>
        )}
      </header>
    </div>
  );
};
