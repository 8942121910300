import { TimePickerProps } from '../TimePicker';

export const sanitiseForNativeInput = (
  value: TimePickerProps['value']
): string => {
  if (!value) return '';

  if (value instanceof Date) {
    const hours = value.getUTCHours().toString().padStart(2, '0');
    const minutes = value.getUTCMinutes().toString().padStart(2, '0');
    const seconds = value.getUTCSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  return value;
};
