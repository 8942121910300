import { DEVICE_WIDTH } from '@tapestry/shared/constants';
import { useMediaQuery } from 'react-responsive';

export interface IAppMediaQueries {
  isPhone: boolean;
  isTablet: boolean;
  isSmallComputerAndUp: boolean;
  isComputer: boolean;
  isLargeScreen: boolean;
  isComputerAndUp: boolean;
  xl: boolean;
}

/**
 * return conditionnal boolean aligns with are Tailwind style guide
 *
 * Alternative - use `useMediaQuery()` if you need custom once
 *
 * @return isPhone - maxWidth: 640
 * @return isTablet - minWidth: 640 - maxWidth: 1024
 * @return isComputer - minWidth: 1024 - maxWidth: 1536
 * @return isLargeScreen - minWidth: 1536
 *
 */
const useAppMediaQuery = (): IAppMediaQueries => {
  const isPhone = useMediaQuery({ maxWidth: DEVICE_WIDTH.phone.maxWidth });
  const isTablet = useMediaQuery({
    minWidth: DEVICE_WIDTH.tablet.minWidth,
    maxWidth: DEVICE_WIDTH.tablet.maxWidth,
  });
  const isSmallComputerAndUp = useMediaQuery({
    minWidth: DEVICE_WIDTH.laptopSmall.minWidth,
  });
  const isComputer = useMediaQuery({
    minWidth: DEVICE_WIDTH.laptop.minWidth,
    maxWidth: DEVICE_WIDTH.laptop.maxWidth,
  });
  const isLargeScreen = useMediaQuery({
    minWidth: DEVICE_WIDTH.largeScreen.minWidth,
  });
  const xl = useMediaQuery({
    minWidth: 1200,
  });

  return {
    isPhone,
    isTablet,
    isSmallComputerAndUp,
    isComputer,
    isLargeScreen,
    isComputerAndUp: isComputer || isLargeScreen,
    xl,
  };
};

export { useMediaQuery, useAppMediaQuery };
