export function isTouchDeviceButNotComputer() {
  let userAgent = navigator.userAgent.toLowerCase();

  let isTouchCapable = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  let isPhoneOrTablet =
    userAgent.includes('mobile') ||
    userAgent.includes('tablet') ||
    userAgent.includes('android') ||
    userAgent.includes('ipad');

  return isTouchCapable && (isPhoneOrTablet || window.innerWidth <= 1024);
}
