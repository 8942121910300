import { encodeObject } from 'use-query-params';
import { VIEW } from '..';
import { THREAD_TYPES } from '@tapestry/types';

export const ROUTE_PATHS = {
  homepage: '/',
  // Auth
  login: '/login',
  logout: '/logout',
  register: '/register',
  recovery: '/password-recovery',
  password_reset: '/password-reset',
  ManageAccount: '/account',
  // Core
  selector: '/selector',
  menu: '/menu',
  search: '/search',
  shop: '/shop',
  scanner: '/scanner',
  // Applets
  tasks: '/tasks',
  createNewTask: '/tasks/create',
  manufacturers: '/manufacturers',
  categories: '/categories',
  departments: '/departments',
  heartbeat: '/rta',
  analytics: '/rta',
  products: '/products',
  spaces: '/spaces',
  deals: '/deals',
  transactions: '/transactions',
  transaction: '/transactions/:transactionId',
  suppliers: '/suppliers',
  staff: '/staff',
  lists: '/lists',
  createNewList: '/lists/create',
  activity: '/activities',
  thread: '/thread/:threadId',
  // Threads
  createNewThread: '/thread/create',
  threadSummary: '/thread/:threadId/summary',
  // Misc
  safetyCulture: 'https://safetyculture.com/',
  afterpay: 'https://www.afterpay.com/en-AU',
  canva: 'https://www.canva.com/en_gb/',
  inviteARetailerTypeform:
    'https://form.typeform.com/to/FVfe4OZR#hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx',
  // Buildable
  build: {
    shop: (id: string): string => `/shop?shopId=${id}`,
    thread: (id: string, defaultView?: VIEW): string =>
      `/thread?id=${id}${defaultView ? `&view=${defaultView}` : ''}`,
    transaction: (id: string, defaultView?: VIEW): string =>
      `/transaction?id=${id}${defaultView ? `&view=${defaultView}` : ''}`,
    threadSummary: (id: string, qpObject?: Record<string, any>): string => {
      return `/thread/summary${
        qpObject
          ? `?id=${encodeURIComponent(id)}&${encodeObject(qpObject, '=', '&')}`
          : `?id=${encodeURIComponent(id)}`
      }`;
    },
    heartbeat: ({
      threadType = null,
      qp = '',
    }: {
      threadType?: string | null;
      qp?: string | null;
    }) => `/rta${threadType ? `/${threadType}` : ''}?${qp}`,
    deal: (id: string): string => `/deals?shopId=${id}`,
    list: { edit: (id: string): string => `/lists/edit/${id}` },
    task: { edit: (id: string): string => `/tasks/edit/${id}` },
  },
};

export const AVAILABLE_RTA_PATHS = THREAD_TYPES.map(
  (threadType) => `/rta/${threadType}`
);
