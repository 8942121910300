import { CodeScannerProductNotFound } from './CodeScannerProductNotFound';
import {
  ISortByEnum,
  useGetPaginatedThreadsLazyQuery,
} from '@tapestry/shared/graphql';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { THREAD_TYPE } from '@tapestry/types';
import { encodeObject } from 'serialize-query-params';
import { FC, useEffect, useState } from 'react';
import { NavBarShopSelector } from '../navbar-search';
import dynamic from 'next/dynamic';
import { trackEvent } from '@tapestry/shared/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadingSpinnerIcon } from '@tapestry/shared/icons';
import PermissionRequest from './components/PermissionRequest';
import CameraView from './components/CameraView';
const DynamicScanner = dynamic(() =>
  import('./Scanner/Scanner').then((mod) => mod.CodeScanner)
);

const toProductRoute = (productId: string) =>
  ROUTE_PATHS.build.heartbeat({
    threadType: THREAD_TYPE.PRODUCT,
    qp: encodeObject(
      {
        productId,
      },
      '=',
      '&'
    ),
  });

export const ScannerPage: FC = () => {
  const router = useRouter();
  const [scannedCode, setScannedCode] = useState<string | null>(null);
  const [productNotFound, setProductNotFound] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [isCheckingPermissionStatus, setIsCheckingPermissionStatus] =
    useState(true);

  const { webAssemblyCodeScanner: webAssemblyCodeScannerFlag } = useFlags();

  const [
    getThreads,
    { loading: isLookingForThread, error: hasThreadError, data },
  ] = useGetPaginatedThreadsLazyQuery({
    onCompleted({ threadPaginationV6 }) {
      const productId = threadPaginationV6?.data?.[0]?.id;

      if (!productId) {
        setProductNotFound(true);

        trackEvent({
          event: 'CodeScanner',
          category: 'code_scanner',
          action: 'scanner_product_not_found',
          label: String(productId),
        });

        return;
      }

      router.push(toProductRoute(productId));
    },
    onError: () => {
      // Note: @maxitron - This is debatable, but technically the product is not found but because of a crash
      setProductNotFound(true);
      setScannedCode(null);

      return;
    },
  });
  const willNavigate = data?.threadPaginationV6?.data?.length;

  function onScanSuccess(decodedText: string) {
    setScannedCode(decodedText);
    getThreads({
      variables: {
        filters: { thread_type: 'product' },
        search: decodedText,
        extraData: false,
        limit: 1,
        page: 1,
        sortBy: ISortByEnum.Relevant,
      },
    });
  }

  const flushFailureState = () => {
    setProductNotFound(false);
    setScannedCode(null);
  };

  useEffect(function checkPermission() {
    (async () => {
      try {
        const result = await navigator.permissions.query({
          name: 'camera' as PermissionName,
        });

        setHasPermission(result.state === 'granted');
      } catch (error) {
        console.error('Error checking camera permission:', error);
        setHasPermission(false);
      }

      setIsCheckingPermissionStatus(false);
    })();
  }, []);

  useEffect(function clearScannedCode() {
    return () => {
      setScannedCode(null);
    };
  }, []);

  if (isCheckingPermissionStatus) {
    return (
      <div className="text-gray-text h-screen flex-col place-content-center px-4 text-center">
        <h1 className="text-lg">Checking permissions...</h1>
      </div>
    );
  }

  if (!hasPermission) {
    return <PermissionRequest setHasPermission={setHasPermission} />;
  }

  if (hasPermission && !scannedCode) {
    return (
      <main className="h-screen">
        <div className="fixed top-0 z-10 mt-4 flex w-full justify-center px-4">
          <div className="bg-primary inline-block rounded-full px-4">
            <NavBarShopSelector inHeader invertTextColor />
          </div>
        </div>

        {webAssemblyCodeScannerFlag ? (
          <CameraView onScanSuccess={onScanSuccess} />
        ) : (
          <DynamicScanner onScanSuccess={onScanSuccess} />
        )}
      </main>
    );
  }

  if (isLookingForThread) {
    return (
      <div className="text-gray-text h-screen flex-col place-content-center items-center px-4 text-center">
        <p className="text-center">
          Searching your database for the corresponding product...
        </p>
        <span className="inline-block">
          <LoadingSpinnerIcon className="mt-4 h-8 w-auto" />
        </span>
      </div>
    );
  }

  if (productNotFound) {
    return (
      <CodeScannerProductNotFound
        handleGoBack={router.back}
        flushFailureState={flushFailureState}
        isError={!!hasThreadError}
      />
    );
  }

  if (willNavigate) {
    return (
      <div className="text-gray-text h-screen flex-col place-content-center items-center px-4 text-center">
        <p className="text-center">Redirecting to Realtime Analytics...</p>
        <span className="inline-block">
          <LoadingSpinnerIcon className="mt-4 h-8 w-auto" />
        </span>
      </div>
    );
  }

  return null;
};
