import { TopBars } from './bars/Topbars';
import { useSpring } from '@react-spring/web';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useProfile } from '@tapestry/shared/hooks';
import { useAppMediaQuery } from '@tapestry/weave';
import { useUIContext } from '@tapestry/shared/client';
import { usePreventScroll } from 'react-aria';
import { loadable } from '@tapestry/shared/lazy-load';
import { RetailPageProps } from '@tapestry/types';
import { NextRouter, useRouter } from 'next/router';
import { ROUTE_PATHS, URL_MESSAGE_ID } from '@tapestry/shared/constants';
import { KeyboardShortcutsAppWide } from '../KeyboardShortcutsAppWide';

const MobileSidebar = loadable(() => import('./bars/MobileSidebar'), {
  chunkName: 'mobile-sidebar',
  ssr: false,
});

const AboveMobileSidebar = loadable(() => import('./bars/AboveMobileSidebar'), {
  chunkName: 'above-mobile-sidebar',
  ssr: false,
});

interface MainPrivateLayoutProps extends RetailPageProps {
  children: ReactNode;
}

/**
 * This offset the page the the left to make space for the static sidebar
 */
const PAGE_LEFT_OFFSET = 'pl-16';

/**
 * As of 20 Sept 2023, the page props are set in `getStaticProps` as we are not using a FE serer yet.
 * In the current iteration of the design, we need the top search bar to be showing on mobile, in the analytics dimensions but not on tablet and above
 * Unfortunetly, the only way to get device info on the server would be to use `getServerSideProps` but because we are using NextJS `output: export`, we are not allowed to use it.
 * hence why we are hijacking the prop on the client
 */
const hijackHideSearchbar = (
  hideSearchbar: boolean | undefined,
  isPhone: boolean,
  pathname: string
) => {
  if (
    isPhone &&
    hideSearchbar &&
    pathname.includes(`${ROUTE_PATHS.analytics}/`)
  ) {
    return false;
  }

  return hideSearchbar;
};

export const MainPrivateLayout: FC<MainPrivateLayoutProps> = ({
  children,
  noShadowOnNavbar = false,
  noBanner,
  hideSearchbar = false,
  hideSidebar,
  public: isPublicPage,
}) => {
  const { xl, isPhone } = useAppMediaQuery();
  const [profile] = useProfile();
  const [{ hasTopBannersShowing }] = useUIContext();
  const { pathname, query } = useRouter();
  const [pageSideOffset, setPageOffset] = useState('');
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const sidebarExpansionSprings = useSpring({
    width: sidebarExpanded ? 256 : 64,
    opacity: sidebarExpanded ? 1 : 0,
  });

  const userIsNotRegistered = (query: NextRouter['query']) => {
    return query?.msgid === URL_MESSAGE_ID.user_not_registered;
  };

  // * see above
  const hijackedHideSearchBarProp = hijackHideSearchbar(
    hideSearchbar,
    isPhone,
    pathname
  );

  // Prevents scrolling of the main content whilst hovering on the sidebar and scrolling it
  const shouldPreventScrolling = sidebarExpanded && !xl;

  useEffect(
    function setPageOffsetByScreenWidth() {
      const correctOffset = !xl || hideSidebar ? '' : PAGE_LEFT_OFFSET;

      if (correctOffset !== pageSideOffset) {
        setPageOffset(correctOffset);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xl, hideSidebar]
  );

  return (
    <KeyboardShortcutsAppWide>
      {hijackedHideSearchBarProp || !profile ? null : (
        <TopBars
          noShadowOnNavbar={noShadowOnNavbar}
          setSidebarExpanded={setSidebarExpanded}
          noBanner={noBanner}
          isPublicPage={isPublicPage}
          userIsNotRegistered={userIsNotRegistered(query)}
        />
      )}
      {hideSidebar || !profile ? null : xl ? (
        // above tablets
        <AboveMobileSidebar
          profile={profile}
          hasTopBannersShowing={hasTopBannersShowing}
          hideSearchbar={hideSearchbar || false}
          sidebarExpansionSprings={sidebarExpansionSprings}
        />
      ) : (
        <MobileSidebar
          sidebarExpanded={sidebarExpanded}
          setSidebarExpanded={setSidebarExpanded}
        />
      )}
      <ScrollPrevention prevent={shouldPreventScrolling}>
        <main className={pageSideOffset}>{children}</main>
      </ScrollPrevention>
    </KeyboardShortcutsAppWide>
  );
};

const ScrollPrevention = ({ prevent, children }) => {
  usePreventScroll({ isDisabled: !prevent });

  return children;
};
