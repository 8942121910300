import { captureException } from '@sentry/nextjs';

type SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
type ErrorContext = {
  error?: Error;
  level?: SeverityLevel;
  tags?: Record<string, string>;
};

export const logError = (message: string, errorContext?: ErrorContext) => {
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
    console.error(message);
  }

  captureException(message, errorContext);
};
